
import "./footer.css"
function Footer(){

    return(

        <footer>
            <div id="Copyright">
                <p>&copy; {new Date().getFullYear()} Bottoms' up bar service. all rights reserved.</p>
            </div> 
        </footer>
    );

}

export default Footer